<template>
  <div class="page seeroo-pages">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3 v-if="contentData != null">{{ contentData.title }}</h3>
          <span class="divider"></span>
<!--          <p>Дүрийн мэдээлэл, тоглосон цувралууд</p>-->
        </div>

        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link v-if="contentData != null" to="/character/all" class="current">
            <span>{{ contentData.title }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="content-page">
      <div class="page-data-wrap">
        <div class="page-content-body container mx-auto">
          <div v-if="isLoading" class="loader-wrap flex">
            <ProgressSpinner/>
          </div>
          <div v-else>
            <div class="content-img" v-if="contentData.thumb != null">
            <img :src="imageUrl(contentData.thumb)" :alt="img" class="img"/>
            </div>
            <p class="text-sm" v-html="contentData.body"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {imageUrl} from "@utils";
export default {
  name: "Content",
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    contentData() {
      return this.$store.state.contentData;
    }
  },
  watch: {
    '$route.params.id'(to, from) {
      if (to !== from) {
        this.$store.dispatch("getContentData", {vm: this, id: this.$route.params.id})
      }
    },
  },
  created() {
    this.$store.dispatch("getContentData", {vm: this, id: this.$route.params.id})
  },
  methods:{
    imageUrl,
  }
}
</script>

<style scoped>
</style>